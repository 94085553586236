import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { TbBackground } from "react-icons/tb";
import { CiSettings } from "react-icons/ci";

const Sidebar = ({ setElements, setState, state }) => {
    return (
        // {/* sidebar menu */}
        <div className='fixed mt-[75px] w-[80px] h-[100%] bg-[#f6f7f8] text-[rgba(0,0,0,0.6)] overflow-y-auto z-30'>
        <Tippy content={<span className='text-[12px]'>Design</span>} placement='right' offset={[2, 0]}>
            <div onClick={() => { setElements('design'); setState('design') }} className="w-full h-[72px] cursor-pointer flex justify-center flex-col items-center gap-1 group" >
                <span className="YIDmbA w-[35px] h-20px p-1 flex justify-center items-center group-hover:bg-slate-100 rounded-md group-hover:shadow-md transition-all duration-200 ease-in-out">
                    <span aria-hidden="true" className={`NA_Img dkWypw group-hover:text-[#13a3b5] ${state === 'design' && "text-[#13a3b5]"}`}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M3 11.325c0-3.59 0-5.385.966-6.61a4.5 4.5 0 0 1 .748-.749C5.94 3 7.734 3 11.325 3h1.35c3.59 0 5.386 0 6.61.966.279.22.53.47.749.748C21 5.94 21 7.734 21 11.325v1.35c0 3.59 0 5.386-.966 6.61-.22.279-.47.53-.748.749-1.226.966-3.02.966-6.611.966h-1.35c-3.59 0-5.385 0-6.61-.966a4.497 4.497 0 0 1-.749-.748C3 18.06 3 16.266 3 12.675v-1.35ZM11.325 4.5H13.5v15h-2.175c-1.83 0-3.076-.002-4.021-.111-.914-.105-1.356-.293-1.661-.533a3.004 3.004 0 0 1-.499-.499c-.24-.305-.428-.747-.533-1.661-.109-.945-.111-2.19-.111-4.021v-1.35c0-1.83.002-3.076.11-4.021.106-.914.293-1.356.534-1.661a3 3 0 0 1 .499-.499c.305-.24.747-.428 1.661-.533.945-.109 2.19-.111 4.021-.111ZM15 19.486c.666-.014 1.22-.042 1.696-.097.914-.105 1.356-.293 1.661-.533.186-.146.353-.314.499-.499.24-.305.428-.747.533-1.661.109-.945.111-2.19.111-4.021v-1.657H15v8.468Zm4.494-9.968c-.01-.904-.037-1.619-.105-2.214-.105-.914-.293-1.356-.533-1.661a3.004 3.004 0 0 0-.499-.499c-.305-.24-.747-.428-1.661-.533A18.58 18.58 0 0 0 15 4.514v5.004h4.494Z" fill="currentColor"></path>
                        </svg>
                    </span>
                </span>
                <span className='text-xs font-medium'>Design</span>
            </div>
        </Tippy>
        

        <Tippy content={<span className='text-[12px]'>Elements</span>} placement='right' offset={[2, 0]}>
            <div onClick={() => { setElements('element', 'svg'); setState('element') }} className="w-full h-[72px] cursor-pointer flex justify-center flex-col items-center gap-1 group">
                <span className="YIDmbA w-[35px] h-20px p-1 flex justify-center items-center group-hover:bg-slate-100 rounded-md group-hover:shadow-md transition-all duration-200 ease-in-out">
                    <span aria-hidden="true" className={`NA_Img dkWypw group-hover:text-[#992bff] ${state === 'element' && "text-[#992bff]"}`}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M6.55 11.242a1.502 1.502 0 0 0 1.42 0l.002-.002.004-.002.01-.005.026-.015.084-.048a9.986 9.986 0 0 0 1.14-.787c.638-.51 1.478-1.312 2.026-2.375a2.991 2.991 0 0 0-4.003-4.16A2.991 2.991 0 0 0 3.2 7.912c.54 1.103 1.402 1.93 2.055 2.454a10.007 10.007 0 0 0 1.25.854l.028.015.01.005.003.002.002.001ZM4.53 7.217c.352.731.918 1.34 1.444 1.794a8.454 8.454 0 0 0 1.285.91s.086-.047.229-.136a8.452 8.452 0 0 0 1.054-.776c.51-.442 1.058-1.03 1.41-1.734a1.491 1.491 0 1 0-2.693-1.208 1.493 1.493 0 0 0-1.435-1.084A1.491 1.491 0 0 0 4.53 7.217ZM17.536 4.011a1.026 1.026 0 0 0-1.775 0l-3.307 5.694a1.026 1.026 0 0 0 .888 1.542h6.614c.79 0 1.285-.857.887-1.542l-3.307-5.694ZM16.65 5.47l-2.485 4.277h4.969L16.649 5.47ZM20.861 17.168a4.213 4.213 0 1 1-8.425 0 4.213 4.213 0 0 1 8.425 0Zm-1.5 0a2.713 2.713 0 1 1-5.425 0 2.713 2.713 0 0 1 5.425 0ZM3.406 14.086a.77.77 0 0 1 .77-.77h6.14a.77.77 0 0 1 .77.77v6.294c0 .34-.275.616-.615.616H4.176a.77.77 0 0 1-.77-.77v-6.14Zm1.5 5.41v-4.68h4.68v4.68h-4.68Z" fill="currentColor"></path>
                        </svg>
                    </span>
                </span>
                <span className='text-xs font-medium'>Elements</span>
            </div>
        </Tippy>

        <Tippy content={<span className='text-[12px]'>Draw</span>} placement='right' offset={[2, 0]}>
            <div onClick={() => { setElements('draw'); setState('draw'); }} className="w-full h-[72px] cursor-pointer flex justify-center flex-col items-center gap-1 group">
                <span className="YIDmbA w-[35px] h-20px p-1 flex justify-center items-center group-hover:bg-slate-100 rounded-md group-hover:shadow-md transition-all duration-200 ease-in-out">
                    <span aria-hidden="true" className={`NA_Img dkWypw group-hover:text-[#0ba84a] ${state === 'draw' && "text-[#0ba84a]"}`}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M20.07 3.456a3.135 3.135 0 0 0-4.434 0L10.25 8.843a3.38 3.38 0 0 0-.884 1.55l-.845 3.292c-.205.8.522 1.527 1.322 1.323l3.278-.837a3.384 3.384 0 0 0 1.555-.886L20.07 7.89a3.135 3.135 0 0 0 0-4.434Zm-2.117 4.43 1.057-1.057a1.635 1.635 0 0 0-2.313-2.313l-1.056 1.057 2.312 2.312Zm-1.166 1.166-3.172 3.172c-.24.24-.539.41-.866.493l-2.602.665.67-2.616a1.88 1.88 0 0 1 .492-.862l3.165-3.164 2.313 2.312Z" fill="currentColor"></path><path d="M5.144 15.022a.641.641 0 1 0 0 1.282h13.751a2.109 2.109 0 0 1 0 4.218H9.194a.75.75 0 0 1 0-1.5h9.701a.609.609 0 1 0 0-1.218H5.144a2.141 2.141 0 0 1 0-4.282h1.862v1.5H5.144Z" fill="currentColor"></path>
                        </svg>
                    </span>
                </span>
                <span className='text-xs font-medium'>Draw</span>
            </div>
        </Tippy>

        <Tippy content={<span className='text-[12px]'>Upload</span>} placement='right' offset={[2, 0]}>
            <div onClick={() => { setElements('uploadImage'); setState('image') }} className="w-full h-[72px] cursor-pointer flex justify-center flex-col items-center gap-1 group">
                <span className="YIDmbA w-[35px] h-20px p-1 flex justify-center items-center group-hover:bg-slate-100 rounded-md group-hover:shadow-md transition-all duration-200 ease-in-out">
                    <span aria-hidden="true" className={`NA_Img dkWypw group-hover:text-[#ff6105] ${state === 'image' && "text-[#ff6105]"}`}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.24 2.985c-2.735 0-5.04 2.075-5.316 4.788a.199.199 0 0 1-.123.162 5.729 5.729 0 0 0 1.994 11.097 5.727 5.727 0 0 0 5.727-5.727v-.486l1.782 1.782a.75.75 0 0 0 1.06-1.06l-3.062-3.063a.75.75 0 0 0-1.06 0L9.179 13.54a.75.75 0 0 0 1.06 1.06l1.783-1.781v.486A4.227 4.227 0 1 1 6.324 9.34a1.698 1.698 0 0 0 1.092-1.416c.198-1.943 1.855-3.44 3.825-3.44a3.848 3.848 0 0 1 3.785 3.174c.135.764.78 1.366 1.563 1.43 2.146.178 3.855 2.016 3.855 4.216a4.226 4.226 0 0 1-4.227 4.227h-1.914a.75.75 0 0 0 0 1.5h1.914a5.727 5.727 0 0 0 5.727-5.727c0-2.978-2.305-5.468-5.231-5.71a.25.25 0 0 1-.21-.196 5.348 5.348 0 0 0-5.262-4.414Z" fill="currentColor"></path>
                        </svg>
                    </span>
                </span>
                <span className='text-xs font-medium'>Upload</span>
            </div>
        </Tippy>

        <Tippy content={<span className='text-[12px]'>Text</span>} placement='right' offset={[2, 0]}>
            <div onClick={() => { setElements('text'); setState('text') }} className="w-full h-[72px] cursor-pointer flex justify-center flex-col items-center gap-1 group">
                <span className="YIDmbA w-[35px] h-20px p-1 flex justify-center items-center group-hover:bg-slate-100 rounded-md group-hover:shadow-md transition-all duration-200 ease-in-out">
                    <span aria-hidden="true" className={`NA_Img dkWypw group-hover:text-[#992bff] ${state === 'text' && "text-[#992bff]"}`}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.266 5.792a1.5 1.5 0 0 1 1.5-1.5h12.468a1.5 1.5 0 0 1 1.5 1.5v1.85a.75.75 0 0 1-1.5 0v-1.35a.5.5 0 0 0-.5-.5H12.75v11.939a.5.5 0 0 0 .5.5h1.875a.75.75 0 0 1 0 1.5h-6.25a.75.75 0 1 1 0-1.5h1.875a.5.5 0 0 0 .5-.5V5.792H6.266a.5.5 0 0 0-.5.5V7.67a.75.75 0 1 1-1.5 0V5.792Z" fill="currentColor"></path>
                        </svg>
                    </span>
                </span>
                <span className='text-xs font-medium'>Text</span>
            </div>
        </Tippy>

        <Tippy content={<span className='text-[12px]'>Projects</span>} placement='right' offset={[2, 0]}>
            <div onClick={() => { setElements('projects'); setState('projects') }} className="w-full h-[72px] cursor-pointer flex justify-center flex-col items-center gap-1 group">
                <span className="YIDmbA w-[35px] h-20px p-1 flex justify-center items-center group-hover:bg-slate-100 rounded-md group-hover:shadow-md">
                    <span aria-hidden="true" className={`NA_Img dkWypw group-hover:text-[#333] ${state === 'projects' && "text-[#333]"}`}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M10.845 2.942H7.03a4 4 0 0 0-4 3.994l-.017 10a4 4 0 0 0 4 4.006h9.993a4 4 0 0 0 4-4v-8.23a3 3 0 0 0-3-3h-3.614a.5.5 0 0 1-.447-.277l-.417-.834a3 3 0 0 0-2.683-1.659Zm-3.815 1.5h3.815a1.5 1.5 0 0 1 1.341.83l.417.834a2 2 0 0 0 1.79 1.106h3.613a1.5 1.5 0 0 1 1.5 1.5v.735H4.526l.004-2.509a2.5 2.5 0 0 1 2.5-2.495Zm-2.507 6.505-.01 5.991a2.5 2.5 0 0 0 2.5 2.505h9.993a2.5 2.5 0 0 0 2.5-2.5v-5.996H4.523Z" fill="currentColor"></path>
                        </svg>
                    </span>
                </span>
                <span className='text-xs font-medium'>Projects</span>
            </div>
        </Tippy>

        <Tippy content={<span className='text-[12px]'>Images</span>} placement='right' offset={[2, 0]}>
            <div onClick={() => { setElements('images', 'png'); setState('initImage') }} className="w-full h-[72px] cursor-pointer flex justify-center flex-col items-center gap-1 group">
                <span className="YIDmbA w-[35px] h-20px p-1 flex justify-center items-center group-hover:bg-slate-100 rounded-md group-hover:shadow-md transition-all duration-200 ease-in-out">
                    <span aria-hidden="true" className={`NA_Img dkWypw group-hover:text-[#992bff] ${state === 'initImage' && "text-[#992bff]"}`}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12.81 4.5h-1.62c-1.765 0-2.965.002-3.878.104-.884.098-1.315.273-1.613.497a3 3 0 0 0-.598.598c-.224.298-.4.729-.497 1.613-.102.913-.104 2.113-.104 3.878v1.62c0 1.765.002 2.965.104 3.878.085.77.23 1.196.413 1.49l7.773-7.773.03-.03c.484-.484.89-.89 1.256-1.183.384-.307.79-.546 1.287-.616.245-.035.495-.035.74 0 .496.07.903.309 1.287.616.365.292.772.7 1.257 1.184l.03.03.823.823v-.039c0-1.765-.002-2.965-.104-3.878-.098-.884-.273-1.315-.497-1.613a3.003 3.003 0 0 0-.598-.598c-.298-.224-.729-.4-1.613-.497-.913-.102-2.113-.104-3.878-.104ZM7.312 19.396c-.515-.057-.877-.14-1.147-.244l7.685-7.686c.522-.522.87-.869 1.163-1.103.28-.224.439-.285.561-.302.106-.015.212-.015.318 0 .122.017.28.078.56.302.293.234.642.581 1.164 1.103L19.5 13.35c-.002 1.475-.013 2.521-.104 3.338-.098.884-.273 1.315-.497 1.613-.17.227-.371.428-.598.598-.298.224-.729.4-1.613.497-.913.102-2.113.104-3.878.104h-1.62c-1.765 0-2.965-.002-3.878-.104ZM3.902 4.798C3 5.998 3 7.73 3 11.19v1.62c0 3.46 0 5.191.901 6.392.255.34.557.641.897.897C5.998 21 7.73 21 11.19 21h1.62c3.46 0 5.191 0 6.392-.901.34-.256.641-.557.897-.897.901-1.2.901-2.931.901-6.392v-1.62c0-3.46 0-5.191-.901-6.392a4.501 4.501 0 0 0-.897-.897C18.002 3 16.27 3 12.81 3h-1.62c-3.46 0-5.191 0-6.392.901a4.5 4.5 0 0 0-.897.897ZM8.5 10.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" fill="currentColor"></path>
                        </svg>
                    </span>
                </span>
                <span className='text-xs font-medium'>Images</span>
            </div>
        </Tippy>

        <Tippy content={<span className='text-[12px]'>Background</span>} placement='right' offset={[2, 0]}>
            <div onClick={() => { setElements('background', 'background'); setState('background') }} className="w-full h-[72px] cursor-pointer flex justify-center flex-col items-center gap-1 group">
                <span className='text-2xl w-[35px] h-20px p-1 flex justify-center rounded-md group-hover:bg-slate-100'><TbBackground className={`text-[25px] flex justify-center items-center group-hover:text-[#333] ${state === 'background' && "text-[#333]"}`} /></span>
                <span className='text-xs font-medium'>Background</span>
            </div>
        </Tippy>

        <Tippy content={<span className='text-[12px]'>Settings</span>} placement='right' offset={[2, 0]}>
            <div onClick={() => { setElements('settings', 'settings'); setState('settings') }} className="w-full h-[72px] cursor-pointer flex justify-center flex-col items-center gap-1 group">
                <span className='text-2xl w-[35px] h-20px p-1 flex justify-center rounded-md group-hover:bg-slate-100'><CiSettings className={`text-[25px] flex justify-center items-center group-hover:text-[#333] ${state === 'settings' && "text-[#333]"}`} /></span>
                <span className='text-xs font-medium'>Settings</span>
            </div>
        </Tippy>
    </div>
    );
};

export default Sidebar;