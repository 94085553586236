import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { FaItalic, FaSyncAlt, FaUnderline, FaAlignLeft, FaAlignCenter, FaAlignRight, } from 'react-icons/fa';
import { RxTransparencyGrid } from "react-icons/rx";
import { MdOutlineFormatAlignCenter, MdVerticalAlignTop, MdOutlineVerticalAlignCenter, MdVerticalAlignBottom, MdContrast, MdOutlineGradient, MdInvertColors, MdPhotoFilter, MdBrightnessMedium, MdBlurLinear,MdDelete, MdSearch  } from "react-icons/md";
import { CiFilter } from "react-icons/ci";
import FontLoader from '../../helper/FontLoader';

const EditorToolbar = ({ colorPickerRef, containerRef, handleClick, editOptions, setEditOptions, isRangeVisible, setIsRangeVisible, isTransparencyVisible, setIsTransparencyVisible, isAlignmentVisible, setIsAlignmentVisible, isFilterVisible, setIsFilterVisible, handleDeleteElement }) => {
    
    const [fonts, setFonts] = useState({});
    const fontsLoaded = useRef(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [showFontSearch, setShowFontSearch] = useState(false);

    useEffect(() => {
        // Only load fonts if they haven't been loaded yet
        if (!fontsLoaded.current) {
            const loadFonts = async () => {
                const loadedFonts = await FontLoader();
                setFonts(loadedFonts);
                fontsLoaded.current = true;
            };

            loadFonts();
        }
    }, []);

    // Predefined fonts
    const predefinedFonts = [
        "'Arial', sans-serif",
        "'Arial Black', sans-serif",
        "'Comic Sans MS', cursive",
        "'Courier New', monospace",
        "'Georgia', serif",
        "'Times New Roman', serif",
        "'Verdana', sans-serif",
        "'Tahoma', sans-serif",
        "'Trebuchet MS', sans-serif",
        "'Lucida Sans Unicode', sans-serif",
        "'Palatino Linotype', serif",
        "'Impact', sans-serif",
        "'Segoe UI', sans-serif",
        "'Helvetica Neue', sans-serif",
        "'Merryweather', serif",
        "'Open Sans', sans-serif",
        "'Roboto', sans-serif",
        "'Lato', sans-serif",
        "'Montserrat', sans-serif",
        "'Source Sans Pro', sans-serif",
        "'Raleway', sans-serif",
        "'Oswald', sans-serif",
        "'PT Sans', sans-serif",
        "'Dancing Script', cursive",
        "'Cursive', cursive",
        "'Italianno', cursive",
        "'Arista ExtraFilled', cursive",
        "'Airstrike', cursive",
        "'Akbar', sans-serif",
        "'Faith', sans-serif",
        "'FanboyHardcore', sans-serif",
        "'Express', sans-serif",
        "'Fail', sans-serif"
    ];

    // Memoize the combined fonts to prevent unnecessary recalculations
    const allFonts = useMemo(() => {
        return { 
            ...fonts, 
            ...predefinedFonts.reduce((acc, font) => {
                const fontName = font.replace(/['"]/g, '').split(',')[0]; // Extract font name
                acc[fontName] = ''; // Placeholder for base64 or other font data
                return acc;
            }, {}) 
        };
    }, [fonts]); // Only recalculate when fonts change

    const filteredFonts = useMemo(()=>{
        const fontList = Object.keys(allFonts);
        if(!searchQuery) return fontList;
        return fontList.filter(fontName => fontName.toLowerCase().includes(searchQuery.toLowerCase()));
    },[allFonts,searchQuery]);

    const handleFontSelect = (fontValue) =>{
        setEditOptions(prevState => ({...prevState, fontFamily : fontValue}));
        setShowFontSearch(false);
        setSearchQuery('')
    }

    useEffect(()=>{
        const handleClickOutSide = (e) =>{
            if(showFontSearch && !e.target.closest('.font-search-container')){
                setShowFontSearch(false);
            }
        };

        document.addEventListener('mousedown',handleClickOutSide);
        return ()=>{
            document.addEventListener('mousedown',handleClickOutSide)
        }
    },[showFontSearch])

    return (
        <div
            ref={containerRef}
            className="fixed w-[41%] h-[45px] bg-[#f6f7f8] p-3 z-10 mt-0 border-[1px] border-solid shadow-lg overflow-y-visible scrollbar-hide flex gap-3 flex-row items-center justify-center rounded-full"
            style={{ top: `105px`, right: `14.6%`, userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }}
        >
            <div className="relative font-search-container">
                <div 
                    className="h-[30px] w-[100px] flex items-center justify-between px-2 border border-gray-300 rounded-md cursor-pointer"
                    onClick={() => setShowFontSearch(!showFontSearch)}
                >
                    <span className="truncate">
                        {editOptions.fontFamily ? editOptions.fontFamily.replace(/['"]/g, '').split(',')[0] : 'Select Font'}
                    </span>
                    <MdSearch size={16} />
                </div>
                
                {showFontSearch && (
                    <div className="absolute top-[32px] left-0 z-50 w-[200px] max-h-[300px] bg-white border border-gray-300 rounded-md shadow-lg overflow-y-auto">
                        <div className="sticky top-0 bg-white border-b border-gray-200 p-2">
                            <input
                                type="text"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                placeholder="Search fonts..."
                                className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#5b53e0]"
                            />
                        </div>
                        <div>
                            {filteredFonts.map((fontName) => (
                                <div 
                                    key={fontName} 
                                    className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                                    style={{ fontFamily: `'${fontName}', sans-serif` }}
                                    onClick={() => handleFontSelect(`'${fontName}', sans-serif`)}
                                >
                                    {fontName}
                                </div>
                            ))}
                            {filteredFonts.length === 0 && (
                                <div className="px-3 py-2 text-gray-500">No fonts found</div>
                            )}
                        </div>
                    </div>
                )}
            </div>
            <div className="inline-flex items-center w-[100px] h-[30px] overflow-hidden border border-gray-300 rounded-sm shadow">
                <button
                    className="w-[30px] bg-gray-200 hover:bg-gray-300 text-gray-700 font-medium border-r border-gray-300"
                    onClick={() =>
                        setEditOptions(prevState => ({
                            ...prevState,
                            fontSize: Number(parseInt(prevState.fontSize) - 1),
                        }))
                    }
                >
                    -
                </button>
                <input
                    type="number"
                    min={0}
                    value={typeof editOptions.fontSize === "number"
                        ? editOptions.fontSize
                        : editOptions.fontSize?.replace("px", "")}
                    onChange={(e) =>
                        setEditOptions(prevState => ({
                            ...prevState,
                            fontSize: Number(e.target.value),
                        }))
                    }
                    className="w-[40px] text-center py-1 text-gray-700 border-0 focus:outline-none"
                />
                <button
                    className=" w-[30px] bg-gray-200 hover:bg-gray-300 text-gray-700 font-medium border-l border-gray-300"
                    onClick={() =>
                        setEditOptions(prevState => ({
                            ...prevState,
                            fontSize: Number(parseInt(prevState.fontSize) + 1),
                        }))
                    }
                >
                    +
                </button>
            </div>
            <div className="w-[30px] h-[30px]">
                <div
                    className="flex relative items-center justify-center bg-gray-200 shadow cursor-pointer"
                    onClick={handleClick}
                >
                    <span className="text-xl font-[600] text-gray-700">A</span>
                    <span
                        className="w-full h-[4px] absolute bottom-0"
                        style={{ backgroundColor: editOptions.color }}
                    ></span>
                </div>

                <input
                    ref={colorPickerRef}
                    type="color"
                    value={editOptions.color}
                    style={{ visibility: 'hidden' }}
                    onChange={(e) =>
                        setEditOptions(prevState => ({
                            ...prevState,
                            color: e.target.value,
                        }))
                    }
                />
            </div>
            <div className="w-[30px] h-[30px]">
                <div className="flex relative items-center justify-center bg-gray-200 shadow cursor-pointer" onClick={() => setEditOptions(prevState => ({ ...prevState, bold: !editOptions.bold }))} >
                    <span className="text-xl font-[600] text-gray-700">B</span>
                </div>
            </div>
            <div className="relative">
                <div className="w-[30px] h-[30px] relative">
                    <div
                        className="flex items-center justify-center bg-gray-200 h-full shadow cursor-pointer"
                        onClick={() => { setIsTransparencyVisible(false); setIsAlignmentVisible(false); setIsFilterVisible(false); setIsRangeVisible((prev) => !prev) }}
                    >
                        <span className="text-xl font-[600] text-gray-700">
                            <FaSyncAlt />
                        </span>
                    </div>

                    {isRangeVisible && (
                        <div
                            className="absolute bg-gray-100 p-3 shadow rounded w-[200px] z-50"
                            style={{
                                top: "40px",
                                left: "50%",
                                transform: "translateX(-50%)",
                            }}
                        >
                            <input
                                type="range"
                                min={-180}
                                max={180}
                                value={editOptions.rotate}
                                className="w-full"
                                onChange={(e) =>
                                    setEditOptions((prevState) => ({
                                        ...prevState,
                                        rotate: e.target.value,
                                    }))
                                }
                            />
                            <div className="text-center text-sm mt-1">
                                Rotation: {editOptions.rotate}°
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="relative">
                <div className="w-[30px] h-[30px] relative">
                    <div
                        className="flex items-center justify-center bg-gray-200 h-full shadow cursor-pointer"
                        onClick={() => { setIsRangeVisible(false); setIsAlignmentVisible(false); setIsFilterVisible(false); setIsTransparencyVisible((prev) => !prev); }}
                    >
                        <span className="text-xl font-[600] text-gray-700">
                            <RxTransparencyGrid />
                        </span>
                    </div>

                    {isTransparencyVisible && (
                        <div
                            className="absolute bg-gray-100 p-3 shadow rounded w-[200px] z-50"
                            style={{
                                top: "40px",
                                left: "50%",
                                transform: "translateX(-50%)",
                            }}
                        >
                            <input
                                type="range"
                                min={0}
                                max={1}
                                step="0.1"
                                value={editOptions.opacity}
                                className="w-full"
                                onChange={(e) =>
                                    setEditOptions((prevState) => ({
                                        ...prevState,
                                        opacity: e.target.value,
                                    }))
                                }
                            />
                            <div className="text-center text-sm mt-1">
                                Transparency: {editOptions.opacity}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="w-[30px] h-[30px]">
                <div className="flex relative items-center h-full justify-center bg-gray-200 shadow cursor-pointer" >
                    <button
                        className={`text-[rgba(0,0,0,0.6)] font-medium rounded transition duration-200`}
                        onClick={() => setEditOptions(prevState => ({ ...prevState, italic: !prevState.italic }))}
                        title="Italic">
                        <FaItalic />
                    </button>
                </div>
            </div>
            <div className="w-[30px] h-[30px]">
                <div className="flex relative items-center h-full justify-center bg-gray-200 shadow cursor-pointer" >
                    <button
                        className={`text-[rgba(0,0,0,0.6)] font-medium rounded transition duration-200`}
                        onClick={() => setEditOptions(prevState => ({ ...prevState, underline: !prevState.underline }))}
                        title="Underline">
                        <FaUnderline />
                    </button>
                </div>
            </div>
            <div className="relative">
                <div className="w-[30px] h-[30px] relative">
                    <div
                        className="flex items-center justify-center bg-gray-200 h-full shadow cursor-pointer"
                        onClick={() => { setIsRangeVisible(false); setIsTransparencyVisible(false); setIsFilterVisible(false); setIsAlignmentVisible((prev) => !prev) }}
                    >
                        <span className="text-xl font-[600] text-gray-700">
                            <MdOutlineFormatAlignCenter />
                        </span>
                    </div>

                    {isAlignmentVisible && (
                        <div
                            className="absolute bg-gray-100 p-3 shadow rounded w-[200px] z-50"
                            style={{
                                top: "40px",
                                left: "50%",
                                transform: "translateX(-50%)",
                            }}
                        >
                            <div className="grid grid-cols-3 gap-2">
                                <span className='col-span-full text-[14px]'>AlignmentX</span>
                                <button
                                    className={`${editOptions?.left && "bg-gray-500 text-white"} text-[rgba(0,0,0,0.6)] font-medium py-2 px-3 rounded transition duration-200 border border-gray-500`}
                                    onClick={() => setEditOptions(prevState => ({ ...prevState, left: !prevState.left, middle: false, right: false }))}
                                    title="Align Left">
                                    <FaAlignLeft />
                                </button>
                                <button
                                    className={`${editOptions?.middle && "bg-gray-500 text-white"} text-[rgba(0,0,0,0.6)] font-medium py-2 px-3 rounded transition duration-200 border border-gray-500`}
                                    onClick={() => setEditOptions(prevState => ({ ...prevState, middle: !prevState.middle, left: false, right: false }))}
                                    title="Align Center">
                                    <FaAlignCenter />
                                </button>
                                <button
                                    className={`${editOptions?.right && "bg-gray-500 text-white"} text-[rgba(0,0,0,0.6)] font-medium py-2 px-3 rounded transition duration-200 border border-gray-500`}
                                    onClick={() => setEditOptions(prevState => ({ ...prevState, right: !prevState.right, left: false, middle: false }))}
                                    title="Align Right">
                                    <FaAlignRight />
                                </button>
                                <span className='col-span-full text-[14px]'>AlignmentY</span>
                                <button
                                    className={`${editOptions?.top && "bg-gray-500 text-white"} text-[rgba(0,0,0,0.6)] font-medium py-2 px-3 rounded transition duration-200 border border-gray-500`}
                                    onClick={() => setEditOptions(prevState => ({ ...prevState, top: !prevState.top, middleVertical: false, bottom: false }))}
                                    title="Align Top">
                                    <MdVerticalAlignTop />
                                </button>
                                <button
                                    className={`${editOptions?.middleVertical && "bg-gray-500 text-white"} text-[rgba(0,0,0,0.6)] font-medium py-2 px-3 rounded transition duration-200 border border-gray-500`}
                                    onClick={() => setEditOptions(prevState => ({ ...prevState, middleVertical: !prevState.middleVertical, top: false, bottom: false }))}
                                    title="Align Middle">
                                    <MdOutlineVerticalAlignCenter />
                                </button>
                                <button
                                    className={`${editOptions?.bottom && "bg-gray-500 text-white"} text-[rgba(0,0,0,0.6)] font-medium py-2 px-3 rounded transition duration-200 border border-gray-500`}
                                    onClick={() => setEditOptions(prevState => ({ ...prevState, bottom: !prevState.bottom, top: false, middleVertical: false }))}
                                    title="Align Bottom">
                                    <MdVerticalAlignBottom />
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="relative">
                <div className="w-[30px] h-[30px] relative">
                    <div
                        className="flex items-center justify-center bg-gray-200 h-full shadow cursor-pointer"
                        onClick={() => { setIsRangeVisible(false); setIsTransparencyVisible(false); setIsAlignmentVisible(false); setIsFilterVisible((prev) => !prev) }}
                    >
                        <span className="text-xl font-[600] text-gray-700">
                            <CiFilter />
                        </span>
                    </div>

                    {isFilterVisible && (
                        <div
                            className="absolute bg-gray-100 p-3 shadow rounded w-[200px] z-50"
                            style={{
                                top: "40px",
                                left: "50%",
                                transform: "translateX(-50%)",
                            }}
                        >
                            <div className='mt-2 col-span-2'>
                                <label className="flex items-center text-gray-700 font-medium mb-2 -ml-[0.5px]">
                                    Visual Filter Controls
                                </label>
                            </div>
                            <div className="mt-1">
                                <label className="flex items-center text-gray-700 font-medium mb-2 -ml-[0.5px]">
                                    <MdBlurLinear className='mr-2 text-[18px] text-[rgba(0,0,0,0.6)]' />
                                    Blur (px)
                                </label>
                                <input
                                    type="range"
                                    min={0}
                                    max={10}
                                    value={editOptions.blur}
                                    className="w-full"
                                    onChange={(e) => setEditOptions(prevState => ({ ...prevState, blur: e.target.value }))}
                                />
                            </div>
                            <div className="mt-1">
                                <label className="flex items-center text-gray-700 font-medium mb-2 -ml-[0.5px]">
                                    <MdBrightnessMedium className='mr-2 text-[18px] text-[rgba(0,0,0,0.6)]' />
                                    Brightness (%)
                                </label>
                                <input
                                    type="range"
                                    min={50}
                                    max={200}
                                    value={editOptions.brightness}
                                    className="w-full"
                                    onChange={(e) => setEditOptions(prevState => ({ ...prevState, brightness: e.target.value }))}
                                />
                            </div>
                            <div className="mt-1">
                                <label className="flex items-center text-gray-700 font-medium mb-2 -ml-[0.5px]">
                                    <MdContrast className='mr-2 text-[18px] text-[rgba(0,0,0,0.6)]' />
                                    Contrast (%)
                                </label>
                                <input
                                    type="range"
                                    min={50}
                                    max={200}
                                    value={editOptions.contrast}
                                    className="w-full"
                                    onChange={(e) => setEditOptions(prevState => ({ ...prevState, contrast: e.target.value }))}
                                />
                            </div>
                            <div className="mt-1">
                                <label className="flex items-center text-gray-700 font-medium mb-2 -ml-[0.5px]">
                                    <MdOutlineGradient className='mr-2 text-[18px] text-[rgba(0,0,0,0.6)]' />
                                    Grayscale (%)
                                </label>
                                <input
                                    type="range"
                                    min={0}
                                    max={200}
                                    value={editOptions.grayscale}
                                    className="w-full"
                                    onChange={(e) => setEditOptions(prevState => ({ ...prevState, grayscale: e.target.value }))}
                                />
                            </div>
                            <div className="mt-1">
                                <label className="flex items-center text-gray-700 font-medium mb-2 -ml-[0.5px]">
                                    <MdInvertColors className='mr-2 text-[18px] text-[rgba(0,0,0,0.6)]' />
                                    Invert (%)
                                </label>
                                <input
                                    type="range"
                                    min={0}
                                    max={100}
                                    value={editOptions.invert}
                                    className="w-full"
                                    onChange={(e) => setEditOptions(prevState => ({ ...prevState, invert: e.target.value }))}
                                />
                            </div>
                            <div className="mt-1">
                                <label className="flex items-center text-gray-700 font-medium mb-2 -ml-[0.5px]">
                                    <MdPhotoFilter className='mr-2 text-[18px] text-[rgba(0,0,0,0.6)]' />
                                    Sepia (%)
                                </label>
                                <input
                                    type="range"
                                    min={0}
                                    max={20}
                                    value={editOptions.sepia}
                                    className="w-full"
                                    onChange={(e) => setEditOptions(prevState => ({ ...prevState, sepia: e.target.value }))}
                                />
                            </div>

                        </div>
                    )}
                </div>
            </div>
            <div className="w-[30px] h-[30px]">
                <div className="flex relative items-center h-full justify-center bg-gray-200 shadow cursor-pointer">
                    <button
                        className="text-[rgba(0,0,0,0.6)] font-medium rounded transition duration-200"
                        onClick={() => handleDeleteElement()}
                        title="Delete Element"
                    >
                        <span className="text-xl font-[600] text-gray-700"><MdDelete className='mr-2 text-[18px] text-[rgba(0,0,0,0.6)]' /></span> {/* You can use an icon here */}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default memo(EditorToolbar);