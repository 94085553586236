import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CreateComponent from '../../components/CreateComponent';

const MainContent = ({ 
    setComponents, 
    parsedPdfPages,
    components,
    reset, 
    setIsElementUpdated, 
    createComponentRef, 
    pdfPages, 
    editOptions, 
    selectedElement, 
    drawState, 
    isSectionActivated, 
    handlePdfPageController, 
    handleMouseMove, 
    setIsDrawActivated, 
    frameCoordinates 
}) => {
    const [reorderedPdfPages, setReorderedPdfPages] = useState(parsedPdfPages);

    // Update reorderedPdfPages when components or parsedPdfPages changes
    useEffect(() => {
        if (parsedPdfPages && components.length === parsedPdfPages.length) {
            const newOrder = components.map(comp => {
                const pageIndex = comp.pageCount; // Assuming pageCount is the original index
                return parsedPdfPages[pageIndex];
            });
            setReorderedPdfPages(newOrder);
        }
    }, [components, parsedPdfPages]);

    const handleDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(components);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setComponents(items);
    };

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="components">
                {(provided) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="w-full flex"
                    >
                        <div className="relative justify-center items-center h-full min-h-[100vh] w-full overflow-y-auto bg-white" style={{ margin: '0 0 0 81px' }}>
                            <div id='pdfContainer' className='w-[75%] ml-[22.5%] bg-white min-h-[90vh] h-full mt-[60px] flex justify-center items-center overflow-hidden'>
                                <div className={`relative w-[41.5%] min-h-[77vh] ${JSON.parse(sessionStorage.getItem('settings'))?.applyEditingRuler ? 'rulerContainer' : ''} ml-3 mr-3 flex flex-wrap`}>
                                    <div className='pdfContainer' key={components.length}>
                                        {components.map((component, index) => (
                                            <Draggable 
                                                key={`component-${component.id}`}
                                                draggableId={`draggable-${component.id}`}
                                                index={index}
                                            >
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        data-pageid={index + 1}
                                                        className={`-mt-[17px] -ml-5`}
                                                    >
                                                        <div
                                                            style={{
                                                                width: `${frameCoordinates.bleedAreaX}px`,
                                                                height: `${frameCoordinates.bleedAreaY}px`,
                                                                border: '2px solid #333'
                                                            }}
                                                            className={`frameBleed flex items-center mt-[12px] ml-[18px] justify-center relative frameBox-${index + 1} scale-[0.9]`}
                                                            onClick={handlePdfPageController}
                                                            onMouseOver={handlePdfPageController}
                                                            onMouseOut={handlePdfPageController}
                                                            onMouseDown={handlePdfPageController}
                                                            onMouseUp={handlePdfPageController}
                                                            onMouseMove={handleMouseMove}
                                                        >
                                                            <div
                                                                id='FramePage'
                                                                style={{
                                                                    width: `${frameCoordinates.pageSizeX}px`,
                                                                    height: `${frameCoordinates.pageSizeY}px`,
                                                                    border: '2px solid #ef4444'
                                                                }}
                                                                className={`flex items-center justify-center`}
                                                            >
                                                                <CreateComponent
                                                                    key={component.id}
                                                                    index={index}
                                                                    info={component}
                                                                    createComponentRef={createComponentRef}
                                                                    pdfPages={pdfPages}
                                                                    parsedPdfContent={reorderedPdfPages[index]} // Use reorderedPdfPages
                                                                    pageIndex={index + 1}
                                                                    setElementStyles={editOptions}
                                                                    selectedElement={selectedElement}
                                                                    drawState={drawState}
                                                                    isSectionActivated={isSectionActivated}
                                                                    reset={reset}
                                                                    setIsElementUpdated={setIsElementUpdated}
                                                                    handlePdfPageController={handlePdfPageController}
                                                                    handleMouseMove={handleMouseMove}
                                                                    setIsDrawActivated={setIsDrawActivated}
                                                                    frameCoordinates={frameCoordinates}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

  export default MainContent;