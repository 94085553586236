// fontInjector.js
import FontLoader from './FontLoader'; // Adjust path as needed

class FontInjector {
  static styleElement = null;

  static async injectFonts() {
    try {
      // Get fonts from cache or load them
      const fonts = await FontLoader();
      
      if (Object.keys(fonts).length === 0) {
        console.warn("⚠️ No fonts available to inject");
        return;
      }
      
      
      
      // Create style element if it doesn't exist yet
      if (!this.styleElement) {
        this.styleElement = document.createElement('style');
        this.styleElement.type = 'text/css';
        document.head.appendChild(this.styleElement);
      }
      
      // Generate CSS for all fonts
      let fontCss = '';
      
      Object.entries(fonts).forEach(([fontName, base64Data]) => {
        fontCss += `
          @font-face {
            font-family: '${fontName}';
            src: url("data:font/ttf;base64,${base64Data}") format('truetype');
            font-weight: normal;
            font-style: normal;
            font-display: swap;
          }
        `;
      });
      
      // Set the CSS content
      this.styleElement.textContent = fontCss;
    } catch (error) {
      console.error("🚨 FontInjector: Error injecting fonts:", error);
    }
  }
  
  static cleanUp() {
    if (this.styleElement && this.styleElement.parentNode) {
      this.styleElement.parentNode.removeChild(this.styleElement);
      this.styleElement = null;
    }
    
    // Clear the font cache as well
    FontLoader.clearCache();
  }
}

export default FontInjector;