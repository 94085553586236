import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";

const Dialogs = ({ popupOpen, setPopupOpen, handleDialogClose }) => {
    return (
        <Dialog open={popupOpen} onClose={() => setPopupOpen(false)}>
            <DialogTitle>Access Denied</DialogTitle>
            <DialogContent>
                <p>You do not have permission to access this link.</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Dialogs;