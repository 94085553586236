// Create a cache module to store loaded fonts
// fontCache.js
let fontCache = null;
let loadingPromise = null;

const FontLoader = async () => {
    // Return cached fonts if available
    if (fontCache) {
        return fontCache;
    }

    // If already loading, return the existing promise
    if (loadingPromise) {
        return loadingPromise;
    }

    // Create a new loading promise
    loadingPromise = (async () => {
        try {

            // Dynamically load fonts using Webpack's require.context
            const fontsFolder = require.context('../assets/fonts', false, /\.ttf$/);
            const fontFiles = fontsFolder.keys();

            const fonts = {};

            // Create an array of fetch promises
            const fetchPromises = fontFiles.map(async (file) => {
                const fontName = file.slice(2, -4); // Extract font name directly
                const fontFile = fontsFolder(file);
                

                try {
                    // Convert to Base64
                    const response = await fetch(fontFile);
                    if (!response.ok) {
                        throw new Error(`Error fetching font: ${response.statusText}`);
                    }

                    const arrayBuffer = await response.arrayBuffer();
                    const base64String = btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)));

                   
                    fonts[fontName] = base64String;
                } catch (error) {
                    // console.error(`❌ ${error.message} for font: ${fontName}`);
                }
            });

            // Wait for all fetch promises to resolve
            await Promise.all(fetchPromises);

          
            
            // Store in cache
            fontCache = fonts;
            return fonts;
        } catch (error) {
            // console.error("🚨 Error loading fonts:", error);
            return {};
        } finally {
            // Reset loading promise
            loadingPromise = null;
        }
    })();

    return loadingPromise;
};

// Add a method to clear the cache if needed
FontLoader.clearCache = () => {
    fontCache = null;
};

export default FontLoader;