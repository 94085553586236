import { useEffect } from 'react';
import './App.css';
import ApplicationRoutes from './Routers/routes';
import FontInjector from './helper/FontInjector';

function App() {

  useEffect(() => {
    window.addEventListener('beforeunload', function () {
      sessionStorage.removeItem('currentPosition');
      this.sessionStorage.removeItem('currentPreview');
    })
  }, []);


  useEffect(() => {
    // Inject fonts when the app loads
    FontInjector.injectFonts();
    
    // Clean up when the app unmounts (optional)
    return () => {
      FontInjector.cleanUp();
    };
  }, []);

  return <ApplicationRoutes />
}

export default App;

