import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from "@mui/material/Alert";

const CustomSnackbar = ({ snackbarOpen, setSnackbarOpen, snackbarSeverity, snackbarMessage }) => {
    return (
        <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={() => setSnackbarOpen(false)}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            className='fixed z-50'
        >
            <Alert
                onClose={() => setSnackbarOpen(false)}
                severity={snackbarSeverity}
                sx={{ width: "100%" }}
            >
                {snackbarMessage}
            </Alert>
        </Snackbar>
    );
};

export default CustomSnackbar;